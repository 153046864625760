<template>
    <div class="flex flex-col h-full">
        <div class="flex-auto">
            <template v-if="$store.state.account">
                <v-spa-layout-sidebar-responsive-link
                    section="scaffolding"
                    :to="{ name: 'accounts.show', params: { account: $store.state.account.uuid } }"
                    icon="far fa-clock"    
                >
                    Scaffolding
                </v-spa-layout-sidebar-responsive-link>
            </template>
        </div>
    </div>
</template>

<script>
    export default {}
</script>
